import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import * as styles from './TeamMember.module.css';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

function TeamMember({ entry, departmentMap }) {
  const { image } = entry;

  const { t } = useTranslation('labels');

  const classes = {
    'grid-item': true,
  };

  if (departmentMap) {
    entry.departments &&
      entry.departments.forEach(function (department) {
        const departmentId = departmentMap[department.id];
        classes['dep-' + departmentId] = true;
      });
  }

  return (
    <div className={clsx(styles.content, classes)}>
      <div className={styles.image}>
        <GatsbyImage alt={''} image={getImage(image)} />
        {/* <Img
          alt={entry.name}
          imgStyle={{ height: 250, objectPosition: 'top center' }}
          style={{ height: 250 }}
          {...image.localFile.processed}
        /> */}
      </div>
      <div className={styles.infos}>
        <h4>
          <span className="fn">{entry.Name}</span>
        </h4>
        {entry.company && (
          <div>
            <p style={{ marginBottom: 0 }}>{entry.company}</p>
          </div>
        )}
        {entry.Aufgabenbereiche && (
          <div>
            {entry.Aufgabenbereiche.map((Aufgabenbereiche, index) => {
              return <p key={index}>{Aufgabenbereiche}</p>;
            })}
          </div>
        )}
        <div>
          {entry.Telefon && (
            <div>
              <span>{t('telephone')} </span>
              <a href={'tel:' + entry.Telefon} target="_blank">
                {entry.Telefon}
              </a>
            </div>
          )}

          {entry.Mobiltelefon && (
            <div>
              <span>{t('cellphone')} </span>
              <a href={'tel:' + entry.Mobiltelefon} target="_blank">
                {entry.Mobiltelefon}
              </a>
            </div>
          )}

          {entry.Fax && (
            <div>
              <span>{t('fax')} </span>
              <a href={'tel:' + entry.Fax} target="_blank">
                {entry.Fax}
              </a>
            </div>
          )}

          {entry.Email && (
            <div>
              <span>{t('email')} </span>
              <a href={'mailto:' + entry.Email}>{entry.Email}</a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TeamMember;
